body {
    margin: 0;
}

* {
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

*::-webkit-scrollbar {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}


*::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}
